#root {
  width: 100%;
  height: 1px;
  min-height: 100%;
  min-height: -webkit-fill-available;
  display: block;
}

.drawer-safearea {
  margin-top: 8px;
  margin-right: 24px;
  box-sizing: border-box;
}

.page-mincontent {
  max-width: 450px;
  margin: 0px auto;
}

.safearea {
  margin-top: 24px;
  margin-bottom: 24px;
}

body {
  overflow-y: auto !important;
}

body.is-snapshot .designer * {
  max-height: inherit !important;
}

body.is-snapshot .designer .fa-Page {
  padding: 0;
  background: transparent;
}

body.is-snapshot .designer .fa-Page:before {
  opacity: 0;
}

.sreen-shot-container div {
  max-height: none;
}

.sreen-shot-container button.css-k08wst-ButtonBase {
  display: none;
}

ul.errors {
  padding: 0 20px;
}

ul.errors li::marker {
  color: #c43e1c;
}
